.text-shadow{
   text-shadow: 1px 1px 2px black;
}

.property-section{
   padding: 1rem;
   margin-top: 1rem;
   margin-bottom: 1rem;
      -webkit-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

 