

$input-focus-width: 0;
$primary: #03aeef;
$secondary: #03aeef;

$headings-font-family: 'Open Sans', sans-serif;

// Import Bootstrap and its default variables

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import '~bootstrap/scss/bootstrap.scss';
 