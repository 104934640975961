.TestimonialsWidget {
    .swiper-slide {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

     .testimonial-slide {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100%;
    }

    .testimonial-content {
        overflow: hidden;
    }

    .testimonial-footer {
        margin-top: auto;
        border-top: 1px solid #ccc;
        padding-top: 0.5rem;
    }

    .swiper-pagination {
        text-align: center;
        padding-top: 2rem;
        position: relative;
    }
    .swiper-pagination-bullet {
        height: 20px;
        width: 20px;
    }
    .swiper-pagination-bullet-active {
        background-color: #535353 !important;
    }
}
