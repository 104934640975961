.DateInput{
   // width: inherit !important;
}

.DayPicker_transitionContainer__verticalScrollable{
   max-height: 80%;
}

#date-picker-container-booking-form .CalendarMonth_1{
   padding: 0px !important;
}

#date-picker-container-booking-form .CalendarMonth {
   padding: 0px !important;
}
